import Vue from "vue";
import depsloader from "../depsloader";
import Listing from "../components/immo/Listing.vue";
import VueAxios from 'vue-axios'

export default () => {
  const element = document.querySelector("#vue-immo-listing");
  if (element) {
    depsloader.load("axios").then((axios) => {
      Vue.use(VueAxios, axios);

      var status = null;
      if (element.dataset.status) {
        status = element.dataset.status;
      }

      new Vue({
        el: element,
        components: {
          Listing
        },
        render: h => h(Listing, { props: { status: status } })
      });
    });
  }
};
