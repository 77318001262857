<template>
<div class="maps-expose">
  <standard-map v-model="map" :value="mapCenter" :zoom="mapZoom" @initialized="onMapLoad" height="25rem"></standard-map>
</div>
</template>

<script>
import StandardMap from "../StandardMap";

export default {
  name: "ImmoExposeMap",

  components: {
    StandardMap
  },

  data() {
    return {
      map: null
    }
  },

  computed: {
    mapCenter() {
      return this.$root.mapCenter;
    },

    mapZoom() {
      if (window.innerWidth < 576) {
        return this.$root.mapZoom - 1;
      }

      return this.$root.mapZoom;
    }
  },

  methods: {
    onMapLoad(o) {
      window._mapMarkers.forEach((elm) => {
        o.L.marker({
          lat: elm.lat,
          lng: elm.lng
        }).bindPopup(elm.html, {
          offset: [185, 160]
        })
        .addTo(o.map);
      });

      this.map = this.mapCenter;
      o.L.control.zoom({
        position: 'bottomleft'
      }).addTo(o.map);
      o.map.zoomControl.remove();
      o.map.scrollWheelZoom.disable();

      o.map.on('popupopen', function(e) {
        var px = o.map.project(e.target._popup._latlng);
        px.y += e.target._popup._container.clientHeight / 6 - 20;
        o.map.panTo(o.map.unproject(px),{animate: true});
      });
    }
  }
}
</script>
